import axios from "axios";
//import Config from './Config';


export async function getDeployments(configData) {

    const baseOperationsApiBaseUrl = configData.UI_CONFIG_OPERATIONS_API_BASE_URI;
    const deploymentApiUrl = baseOperationsApiBaseUrl + "/v1/deployments";
    //const deploymentApiUrl = "https://tf-operations.nonprod.trustedfabric.org/v1/deployments";
    console.log("deploymentApiUrl", deploymentApiUrl);
    try {
      const response = await axios.get(deploymentApiUrl);
      console.log("Got data");
      return response.data || [];
    } catch (error) {
      throw new Error(
        `Error in 'axiosGetJsonData(${deploymentApiUrl})': ${error.message}`
      );
    }

}

