//import axios from "axios";

//import Config from '../services/Config';
class EncryptionService {

    decryptValueX() {
        // axios.get(CONFIG_FILE).then(res => {
        //     const persons = res.data;
        //     this.setState({ persons });
        // })
    }


    encryptValue(config, encryptorPassword, decryptedValue) {
        const baseUrl = config.contents.UI_CONFIG_OPERATIONS_API_BASE_URI;
        return fetch(baseUrl + "/v1/encryptValue",
            {
                credentials: 'include',
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json"
                },
                "body": JSON.stringify({
                    "encryptorPassword": encryptorPassword,
                    "value": decryptedValue
                })
            })
            .then(response => response.json())
    }


    decryptValue(config, encryptorPassword, encryptedValue) {
        const baseUrl = config.contents.UI_CONFIG_OPERATIONS_API_BASE_URI;
        return fetch(baseUrl + "/v1/decryptValue",
            {
                credentials: 'include',
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json"
                },
                "body": JSON.stringify({
                    "encryptorPassword": encryptorPassword,
                    "value": encryptedValue
                })
            })
            .then(response => response.json())
    }

    encryptContents(config, encryptorPassword, decryptedValue) {
        const baseUrl = config.contents.UI_CONFIG_OPERATIONS_API_BASE_URI;
        return fetch(baseUrl + "/v1/encryptContents",
            {
                credentials: 'include',
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json"
                },
                "body": JSON.stringify({
                    "encryptorPassword": encryptorPassword,
                    "value": decryptedValue
                })
            })
            .then(response => response.json())
    }

    decryptContents(config, encryptorPassword, encryptedValue) {
        const baseUrl = config.contents.UI_CONFIG_OPERATIONS_API_BASE_URI;
        return fetch(baseUrl + "/v1/decryptContents",
            {
                credentials: 'include',
                "method": "POST",
                "headers": {
                    "content-type": "application/json",
                    "accept": "application/json"
                },
                "body": JSON.stringify({
                    "encryptorPassword": encryptorPassword,
                    "value": encryptedValue
                })
            })
            .then(response => response.json())
    }    

}

export default new EncryptionService();