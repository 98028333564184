import React, { useEffect, useState } from 'react';
import { Layout, Menu } from 'antd';
import { useQueryParam, StringParam } from 'use-query-params';

import './App.less';

// Icons: https://ant.design/components/icon/
import {
  TableOutlined,
  WalletOutlined,
  MenuUnfoldOutlined,
  MenuFoldOutlined,
} from '@ant-design/icons';
import DeploymentsTable from './components/DeploymentsTable';
import VersionsTable from './components/VersionsTable';
import EventsTable from './components/EventsTable';

import EncryptValue from './components/EncryptValue';
import DecryptValue from './components/DecryptValue';
import EncryptContents from './components/EncryptContents';
import DecryptContents from './components/DecryptContents';

const { Header, Content, Sider } = Layout;
const { SubMenu } = Menu;


function App() {

  const [collapsed, setCollapsed] = React.useState(false);
  const [menuId='tf-apps', setMenuId] = useQueryParam('menuId', StringParam);
  const [subMenuId, setSubMenuId] = useQueryParam('subMenuId', StringParam);

  const components = {
    'tf-apps': <DeploymentsTable />,
    'tf-versions': <VersionsTable />,
    'tf-events': <EventsTable />,
    'encrypt-single-value': <EncryptValue/>,
    'decrypt-single-value': <DecryptValue />,
    'encrypt-entire-file': <EncryptContents/>,
    'decrypt-entire-file': <DecryptContents/>,
    'menu-links': <div>Links to: tfconfigs, tfpipeline, sample-lib, Kibana, wiki (internal + exteranl), GitLab Libs, Jira</div>
  };

  const [render, updateRender] = useState(menuId);

  const handleMenuClick = menu => {
    console.log("menu:" + menu.key)
    setMenuId(menu.key, 'replaceIn')
    updateRender(menu.key);
  };

  useEffect(() => {
   
  });


  return (

    <Layout style={{ height: "100vh" }}>
      <Header className="App-header" >
        <span>TrustedFabric</span>
        {React.createElement(collapsed ? MenuUnfoldOutlined : MenuFoldOutlined, {
          className: 'trigger',
          onClick: () => setCollapsed(!collapsed),
        })}
        {/* <Button type="primary" onClick={() => setSubMenuId(`str${Math.random()}`)}>
            Test
        </Button> */}
      </Header>
      <Layout className="layout">
        <Sider collapsed={collapsed} onCollapse={(collapsed) => setCollapsed(collapsed)} >
          <Menu
            onSelect={handleMenuClick}
            defaultSelectedKeys={[menuId]}
            defaultOpenKeys={[subMenuId]}
            onOpenChange={(openKeys) => { const lastItem = [...openKeys].pop(); console.log(lastItem); setSubMenuId(lastItem,'replaceIn')} }
            mode="inline"
          //mode="vertical"
          //triggerSubMenuAction="click"
          >
            <Menu.Item key="tf-apps" icon={<TableOutlined />}>
              Microservices
            </Menu.Item>
            <Menu.Item key="tf-versions" icon={<TableOutlined />}>
              Versions
            </Menu.Item>
            <Menu.Item key="tf-events" icon={<TableOutlined />}>
              Events
            </Menu.Item>
            <SubMenu
              key="encryption"
              icon={<WalletOutlined />}
              title="Encryption"
              popupOffset="[0,0]"
            >
              <Menu.Item key="encrypt-single-value">Encrypt Single Value</Menu.Item>
              <Menu.Item key="decrypt-single-value">Decrypt Single Value</Menu.Item>
              <Menu.Item key="encrypt-entire-file">Encrypt Entire File</Menu.Item>
              <Menu.Item key="decrypt-entire-file">Decrypt Entire File</Menu.Item>
            </SubMenu>
            {/* <Menu.Item key="menu-links" icon={<ReadOutlined />}>
              Links
            </Menu.Item> */}
          </Menu>
        </Sider>
        <Layout className="layout" style={{  }}>
          {/* <Breadcrumb style={{ padding: '20px 20px 20px 20px' }}>
            <Breadcrumb.Item>Home</Breadcrumb.Item>
            <Breadcrumb.Item>Level1</Breadcrumb.Item>
            <Breadcrumb.Item>Level2</Breadcrumb.Item>
          </Breadcrumb> */}
          <div style={{margin:'10px'}} />
          <Content className="content" >
            {/* Content<br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>Test1<br/><br/><br/><br/><br/>Test2<br/><br/><br/><br/><br/>Test3<br/><br/><br/><br/><br/>Test4 */}
            {components[render]}
          </Content>
        </Layout>
      </Layout>
    </Layout>

  );

}

export default App;
