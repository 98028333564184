
import { atom, selector } from "recoil";
import { getConfig } from "../services/ConfigService";

// https://javascript.plainenglish.io/getting-to-know-recoil-initializing-and-maintaining-react-application-state-with-asynchronous-c5c3eb114c39


/**
 * Populate the default selector return value with a service call.
 */
export const loadConfigState = selector({
  key: "loadConfigState",
  get: async ({ get }) => {
    try {
      const response = await getConfig();
      console.log("new getConfig called... data:", response);
      return response || {};
    } catch (error) {
      console.error(`loadConfigState -> getConfig() ERROR: \n${error}`);
      return {};
    }
  }
});

/**
 * This is the atom the UI components will use to display state.
 */
export const configState = atom({
  key: "configState",
  default: loadConfigState
});


export const getGitlabBaseUrl = (config) => {
    return config.contents.UI_CONFIG_OPERATIONS_GITLAB_BASE_URL;
}


export const getOperationsApiBaseUrl = (config) => {
    return config.contents.UI_CONFIG_OPERATIONS_API_BASE_URI;
}

