import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilStateLoadable } from "recoil";
import { Card, Form, Input, Button, notification, Tooltip } from 'antd';
import {
  CopyTwoTone
} from '@ant-design/icons';

import { encryptorPasswordStore } from "../store";
import EncryptionService from '../services/EncryptionService';
import { configState } from "../store/config-store";

const { TextArea } = Input;

const DecryptValue = () => {

  const [form] = Form.useForm();
  const [encryptorPassword, setEncryptorPassword] = useRecoilState(encryptorPasswordStore);

  const [decryptedValueHidden, setDecryptedValueHidden] = useState(1);
  const [clipboardTooltipText, setClipboardTooltipText] = useState("");
  const [config] = useRecoilStateLoadable(configState);

  const onFinish = (formValues) => {

    form.setFieldsValue({
      decryptedValue: ''
    })

    EncryptionService.decryptValue(
      config,
      formValues.encryptorPassword,
      formValues.encryptedValue)
      .then(result => {
        console.log("result: " + JSON.stringify(result));
        if (result.httpStatusCode < 400) {
          form.setFieldsValue({
            decryptedValue: result.data
          })
          setDecryptedValueHidden(false)
        } else {
          setDecryptedValueHidden(true)

          notification.error({
            message: 'Decrypt Error',
            description: result.appErrorCode + ": " + result.appErrorDesc,
          });
        }
      })
      .catch(err => {
        setDecryptedValueHidden(true)
        console.log("Error", err);
        notification.error({
          message: 'Decrypt Error',
          description: "Error: " + err.message,
        });
      });


    // //const baseUrl = "https://tf-operations.nonprod.trustedfabric.org";
    // const baseUrl = "http://localhost:8080";
    // fetch(baseUrl + "/v1/decryptValue",
    //   {
    //     credentials: 'include',
    //     "method": "POST",
    //     "headers": {
    //       "content-type": "application/json",
    //       "accept": "application/json"
    //     },
    //     "body": JSON.stringify({
    //       "encryptorPassword": formValues.encryptorPassword,
    //       "value": formValues.encryptedValue
    //     })
    //   })
    //   .then(response => response.json())
    //   .then(result => {
    //     console.log("result: " + JSON.stringify(result));
    //     if (result.httpStatusCode < 400) {
    //       form.setFieldsValue({
    //         decryptedValue: result.data
    //       })
    //       setDecryptedValueHidden(false)
    //       //navigator.clipboard.writeText(result.data)
    //     } else {
    //       setDecryptedValueHidden(true)

    //       notification.error({
    //         message: 'Decrypt Error',
    //         description: result.appErrorCode + ": " + result.appErrorDesc,
    //       });

    //     }
    //   })
    //   .catch(err => {
    //     setDecryptedValueHidden(true)
    //     console.log("Error", err);
    //     notification.error({
    //       message: 'Decrypt Error',
    //       description: "Error: " + err.message,
    //     });
    //   });

  }

  useEffect(() => {
    form.setFieldsValue({
      encryptorPassword: encryptorPassword
    })
  });

  return (

    <Card bordered={false} title="Decrypt Value">

      <Form form={form}
        name="control-hooks"
        labelCol={{ span: 5 }}
        labelWrap
        wrapperCol={{ flex: 1 }}
        //initialValues={{ remember: true }}
        requiredMark={false}
        onFinish={onFinish}>


        <Form.Item
          name="encryptorPassword"
          label="Encryptor Password"
          tooltip="This is the value used for JASYPT_ENCRYPTOR_PASSWORD"
          rules={[
            {
              required: true, message: 'Encryptor password required. This is the value of JASYPT_ENCRYPTOR_PASSWORD environment variable'
            },
          ]}
        >
          <Input
            style={{ width: '15em' }}
            placeholder="Enter Encryptor Password"
            allowClear
            onChange={(e) => setEncryptorPassword(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          name="encryptedValue"
          label="Encrypted Value"
          rules={[
            {
              required: true, message: 'Enter value to be decrypted'
            },
          ]}
        >
          <TextArea rows={1} autoSize={true} placeholder="Enter Encrypted Value" allowClear />
        </Form.Item>
        {/* <Form.Item label=" " colon={false}> */}
        <Form.Item wrapperCol={{ offset: 5 }}>
          <Button type="primary" htmlType="submit">
            Decrypt
          </Button>
        </Form.Item>


        <Form.Item
          label={
            <div>
            <span>Decrypted Value</span>
            <Tooltip 
            title={clipboardTooltipText} 
            placement="bottomRight" 
            autoAdjustOverflow={false}
            getPopupContainer={triggerNode => {return document.getElementById("root")	}}
             >
              <Button
                type="text"
                icon={<CopyTwoTone />}
                onMouseEnter={() => setClipboardTooltipText("Copy value to Clipboard")}
                onClick={() => { setClipboardTooltipText("Copied!"); navigator.clipboard.writeText(form.getFieldValue("decryptedValue")) }}
              />
            </Tooltip>
            </div>
          }
          hidden={decryptedValueHidden}
          style={{ marginBottom: 0 }}
        >
          
          {/* Removed the copy-to-clipboard button because the Tooltip kept locking up chrome (recursive loop) */}
          {/* <Form.Item
            style={{ display: 'inline-block', width: '25px' }}
          >
            <Tooltip 
            title={clipboardTooltipText} 
            placement="bottomRight" 
            autoAdjustOverflow={false}
            getPopupContainer={triggerNode => {return document.getElementById("root")	}}
             >
              <Button
                type="text"
                icon={<CopyTwoTone />}
                onMouseEnter={() => setClipboardTooltipText("Copy value to Clipboard")}
                onClick={() => { setClipboardTooltipText("Copied!"); navigator.clipboard.writeText(form.getFieldValue("decryptedValue")) }}
              />
            </Tooltip>
          </Form.Item>  */}
          
          <Form.Item name="decryptedValue"
            style={{  margin: '0px 0px 0px 0px' }}
          >
         

          {/* <Form.Item name="decryptedValue"> */}
            <TextArea rows={1} autoSize={true} />
          </Form.Item>

          {/* <Form.Item>
            <Tooltip 
            title={clipboardTooltipText} 
            placement="bottomRight" 
            autoAdjustOverflow={false}
            getPopupContainer={triggerNode => {return document.getElementById("root")	}}
             >
              <Button
                type="text"
                icon={<CopyTwoTone />}
                onMouseEnter={() => setClipboardTooltipText("Copy value to Clipboard")}
                onClick={() => { setClipboardTooltipText("Copied!"); navigator.clipboard.writeText(form.getFieldValue("decryptedValue")) }}
              />
            </Tooltip>
          </Form.Item>  */}

        </Form.Item>


      </Form>

    </Card>
  )
}

export default DecryptValue


/*


  return (
    <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <div>Decrypt Value</div>
        <Input placeholder="Enter Encrypted Value" />
        <Space size="small">
            <Button type="primary" onClick={() => connect()} >Decrypt</Button>
            <Button >Hello</Button>
        </Space>
        <br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/><br/>
        <Button >Hello2</Button>
    </Space>
  )
  */
