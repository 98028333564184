
import { atom, selector, DefaultValue } from "recoil";
import { configState } from "./config-store";
import { getEvents } from "../services/EventService";

// https://javascript.plainenglish.io/getting-to-know-recoil-initializing-and-maintaining-react-application-state-with-asynchronous-c5c3eb114c39


const eventListReloadTrigger = atom({
    key: "eventListReloadTrigger",
    default: 0
  });


/**
 * Populate the default selector return value with a service call.
 */
export const allEventsState = selector({
  key: "allEventsState",
  get: async ({ get }) => {

    get(eventListReloadTrigger);
    
    const configData = get(configState);
    console.log("new config:", configData);

    try {
      const response = await getEvents(configData);
      console.log("getEvents called...");
      return response.data.events || [];
    } catch (error) {
      console.error(`allEventsState -> getEvents() ERROR: \n${error}`);
      return [];
    }
  },
  
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(eventListReloadTrigger, v => v + 1);
    }
  }

});

/**
 * This is the atom the UI components will use to display state.
 */
export const eventListState = atom({
  key: "eventListState",
  default: allEventsState
});