import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilStateLoadable } from "recoil";
import { Card, Form, Input, Button, notification, Tooltip } from 'antd';
import {
  CopyTwoTone
} from '@ant-design/icons';


import { encryptorPasswordStore } from "../store";
import EncryptionService from '../services/EncryptionService';
import { configState } from "../store/config-store";

const { TextArea } = Input;

const placeholder=
            "Paste contents of entire yaml file here. Wrap values to be encrypted with DEC(value). Example:\n\n" +
            "database:\n" +
            "  username: my_username\n" +
            "  password: DEC(my_secret_password)"



const EncryptContents = () => {

  const [form] = Form.useForm();
  const [encryptorPassword, setEncryptorPassword] = useRecoilState(encryptorPasswordStore);
  const [outputValueHidden, setOutputValueHidden] = useState(1);
  const [clipboardTooltipText, setClipboardTooltipText] = useState("");
  const [config] = useRecoilStateLoadable(configState);

  const onFinish = (formValues) => {

    form.setFieldsValue({
      encryptedValue: ''
    })

    EncryptionService.encryptContents(
      config,
      formValues.encryptorPassword,
      formValues.decryptedValue)
      .then(result => {
        console.log("result: " + JSON.stringify(result));
        if (result.httpStatusCode < 400) {
          form.setFieldsValue({
            encryptedValue: result.data
          })
          setOutputValueHidden(false)
        } else {
          setOutputValueHidden(true)
          notification.error({
            message: 'Encrypt Error',
            description: result.appErrorCode + ": " + result.appErrorDesc,
          });
        }
      })
      .catch(err => {
        setOutputValueHidden(true)
        console.log("Error", err);
        notification.error({
          message: 'Encrypt Error',
          description: "Error: " + err.message,
        });
      });

  }

  useEffect(() => {
    form.setFieldsValue({
      encryptorPassword: encryptorPassword
    })
  });

  return (

    <Card bordered={false} title="Encrypt Contents">

      <Form form={form}
        name="control-hooks"
        labelCol={{ span: 5 }}
        labelWrap
        wrapperCol={{ flex: 1 }}
        initialValues={{ remember: true }}
        requiredMark={false}
        onFinish={onFinish}>

        <Form.Item
          name="encryptorPassword"
          label="Encryptor Password"
          tooltip="This is the value used for JASYPT_ENCRYPTOR_PASSWORD"
          rules={[
            {
              required: true, message: 'Encryptor password required. This is the value of JASYPT_ENCRYPTOR_PASSWORD environment variable'
            },
          ]}
        >
          <Input
            style={{ width: '15em' }}
            placeholder="Enter Encryptor Password"
            allowClear
            onChange={(e) => setEncryptorPassword(e.target.value)}
          />
        </Form.Item>

        <Form.Item
          name="decryptedValue"
          label="Decrypted Contents"
          rules={[
            {
              required: true, message: 'Enter contents of entire yaml file with sensitive values wrapped with DEC(value)'
            },
          ]}
        >
          <TextArea 
            rows={7} 
            autoSize={{ minRows: 7, maxRows: 10000 }} 
            placeholder={placeholder}
            allowClear />
        </Form.Item>
        <Form.Item label=" " colon={false}>
          <Button type="primary" htmlType="submit">
            Encrypt
          </Button>
        </Form.Item>

        <Form.Item          
          label={
            <div>
            <span>Encrypted Contents</span>
            <Tooltip 
            title={clipboardTooltipText} 
            placement="bottomRight" 
            autoAdjustOverflow={false}
            getPopupContainer={triggerNode => {return document.getElementById("root")	}}
             >
              <Button
                type="text"
                icon={<CopyTwoTone />}
                onMouseEnter={() => setClipboardTooltipText("Copy value to Clipboard")}
                onClick={() => { setClipboardTooltipText("Copied!"); navigator.clipboard.writeText(form.getFieldValue("encryptedValue")) }}
              />
            </Tooltip>
            </div>
          }

          
          hidden={outputValueHidden}
          style={{ marginBottom: 0 }}
        >
          <Form.Item name="encryptedValue">
            <TextArea rows={1} autoSize={true} />
          </Form.Item>
        </Form.Item>

      </Form>
    </Card>
  )
}

export default EncryptContents


