
import { useRecoilStateLoadable, useResetRecoilState } from "recoil";
import { Button, Badge, Tag, Tooltip, Table } from 'antd';
import moment from 'moment';

import { getGitlabBaseUrl, configState } from "../store/config-store";
import { deploymentListState, allDeploymentsState } from "../store/deployment-store";

import '../App.less';

const DeploymentsTable = () => {

  const [config] = useRecoilStateLoadable(configState);
  const [deploymentsData] = useRecoilStateLoadable(deploymentListState);

  const resetDeploymentsData = useResetRecoilState(allDeploymentsState);
  const logUrl="https://kibana.nonprod.trustedfabric.org/app/discover#/";

  let kibanaIndexes=[]
  kibanaIndexes['development']="c58151f0-9a84-11ec-9f62-1b222399888a"
  kibanaIndexes['development2']="131c5710-1371-11ed-ae5d-6d79f46f2a2d"
  kibanaIndexes['qa']="4cb8eac0-9085-11ec-9f62-1b222399888a"
  kibanaIndexes['qa2']="bdf06f30-f3d4-11ec-ae5d-6d79f46f2a2d"
  kibanaIndexes['uat']="d2818840-92d0-11ec-9f62-1b222399888a"
  kibanaIndexes['uat2']="8cc5c0c0-dac7-11ec-ae5d-6d79f46f2a2d"
  kibanaIndexes['support']="04bfea50-d222-11ec-ae5d-6d79f46f2a2d"
  kibanaIndexes['hip-development']="909b89a0-9e53-11ed-94a0-3b6918db5226"
  kibanaIndexes['hip-development2']="72f9ef80-a188-11ed-94a0-3b6918db5226"
  kibanaIndexes['uk']="b5f5d110-132f-11ed-ae5d-6d79f46f2a2d"
  kibanaIndexes['demo']="ecb782c0-9cfa-11ed-94a0-3b6918db5226"
  kibanaIndexes['demo2']="fbff19f0-9cfa-11ed-94a0-3b6918db5226"

  //const refreshDeployments = useRecoilRefresher_UNSTABLE(allDeploymentsState());

  //const rowSelection = {
  // selectedRowKeys: this.state.selectedRowKeys,
  // onChange: this.onSelectedRowKeysChange,
  // hideSelectAll: true,
  // columnWidth: 0, // Set the width to 0
  // renderCell: () => "", // Render nothing inside
  // getCheckboxProps: () => ({
  //     className: "tableSelectCheckboxOverride"
  // })
  //};

  const selectRow = (selectedJob) => {
    // const selectedRowKeys = [ selectedJob.key ];
    // this.setState({ selectedRowKeys });

    // var jobId = selectedJob.id;
    // var jobLogs = this.state.jobLogsMap.get(jobId);
    // console.log("Job selected:", selectedJob);
    // console.log("Job selected:", jobLogs);
    // this.setState({selectedJob: selectedJob, selectedJobLogs: jobLogs});
  }

  const getColumns = () => {
    return [
      // {
      //   key: 1,
      //   field: "uniqueKey",
      //   dataIndex: "uniqueKey",
      //   title: "uniqueKey",
      //   fixed: 'left',
      //   width: "90px",
      //   //render: jobIdRenderer,
      // },
      {
        key: 2,
        field: "namespace",
        dataIndex: "namespace",
        title: "Namespace",
        width: "100px",
        fixed: 'left',
        ellipsis: true,
        sorter: {
          compare: (a, b) => a.namespace.localeCompare(b.namespace),
          multiple: 1,
        },
        defaultSortOrder: "ascend",
      },
      {
        key: 3,
        field: "name",
        dataIndex: "name",
        title: "Deployment Name",
        width: "100px",
        fixed: 'left',
        ellipsis: true,
        sorter: {
          compare: (a, b) => a.name.localeCompare(b.name),
          multiple: 1,
        },
        defaultSortOrder: "ascend",
        render: (text, record) => 
        {
            const healthlyPods = record.pods.every(function(pod) {
                if (pod.containers) {
                    return pod.containers.every(function(container) {
                        return container.started===true && container.ready===true;
                    })
                } else {
                    return false;
                }
            });

            return (
                <span>
                    {text}
                    <Tooltip title="At least one pod is unhealthy">
                        &nbsp;
                        {healthlyPods?null:<Badge status="red" />}
                    </Tooltip>
                </span>
            )
        }
      },
      {
        key: 4,
        field: "version",
        dataIndex: "version",
        title: "Version",
        width: "60px",
        sorter: {
          compare: (a, b) => a.version.localeCompare(b.version),
        },
        render: (text, record) => 
        {
            // Check if all pod version match the deplyment version.
            // If not, there is a rolling upgrade in progress (or failed)
            const versionsMatch = record.pods.every(function(pod) {
                return pod.version==null || pod.version === text;
             });

            if (versionsMatch===true) {
                return (
                    <span>
                    {text}
                    </span>
                )
            } else {
                let tooltipText="Multiple versions means a rolling upgrade is in progress or failed. Helm version "+text
                return (
                    <span>
                        <Tooltip title={tooltipText}>
                            Multiple
                        </Tooltip>
                    </span>
                )
            }



        }

      },
      {
        key: 5,
        field: "creationTimestamp",
        dataIndex: "creationTimestamp",
        title: "Deployed",
        width: "80px",
        //render: runDurationRenderer,
        ellipsis: true,
        sorter: {
          compare: (a, b) => a.creationTimestamp.localeCompare(b.creationTimestamp),
        },
        render: (text, record) => (
          <Tooltip title={moment(text).format("YYYY-MM-DD hh:mm:ss a")}>
            <span>{text?moment(text).fromNow():''}</span>
          </Tooltip>
        ),
      },
      {
        key: 6,
        field: "desiredReplicas",
        dataIndex: "desiredReplicas",
        title: "desired",
        width: "60px",
        ellipsis: true,
        sorter: {
          compare: (a, b) => a.desiredReplicas > b.desiredReplicas,
          multiple: 1,
        },
      },
    //   {
    //     key: 7,
    //     field: "totalReplicas",
    //     dataIndex: "totalReplicas",
    //     title: "total",
    //     width: "50px",
    //     ellipsis: true,
    //     sorter: {
    //       compare: (a, b) => a.totalReplicas > b.totalReplicas,
    //     },
    //   },
    //   {
    //     key: 8,
    //     field: "readyReplicas",
    //     dataIndex: "readyReplicas",
    //     title: "ready",
    //     width: "50px",
    //     ellipsis: true,
    //     sorter: {
    //       compare: (a, b) => a.readyReplicas > b.readyReplicas,
    //     },
    //   },
      {
        key: 9,
        field: "availableReplicas",
        dataIndex: "availableReplicas",
        title: "available",
        width: "65px",
        ellipsis: true,
        sorter: {
          compare: (a, b) => a.availableReplicas > b.availableReplicas,
        },
      },
      {
        key: 10,
        field: "unavailableReplicas",
        dataIndex: "unavailableReplicas",
        title: "unavailable",
        width: "80px",
        ellipsis: true,
        sorter: {
          compare: (a, b) => a.unavailableReplicas > b.unavailableReplicas,
        },
      },

      {
        key: 11,
        field: "containerTotalRestartCount",
        dataIndex: "containerTotalRestartCount",
        title: "Restarts",
        width: "100px",
        ellipsis: false,
        sorter: {
          compare: (a, b) => a.containerTotalRestartCount > b.containerTotalRestartCount,
        },
      },

      {
        key: "links",
        field: "links",
        dataIndex: "links",
        title: "Links",
        width: "100px",
        ellipsis: false,
        render: (text, record) => {
            let appName=record.name
            let indexGuid=kibanaIndexes[record.namespace]
            let logParam="?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(columns:!('@timestamp',kubernetes.pod_name,message,java.level,java.logger,java.thread,java.stack),filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'"+indexGuid+"',key:kubernetes.pod_name,negate:!f,params:(query:"+appName+"),type:phrase),query:(match_phrase:(kubernetes.pod_name:"+appName+")))),index:'"+indexGuid+"',interval:auto,query:(language:kuery,query:''),sort:!(!('@timestamp',desc)))"
            let logFullUrl=logUrl+logParam

            // Check if all pod version match the deplyment version.
            // If not, there is a rolling upgrade in progress (or failed)
            const versionsMatch = record.pods.every(function(pod) {
                return pod.version==null || pod.version === record.version;
            });

            let pipelineUrl=null
            if (versionsMatch===true) {
                pipelineUrl=getGitlabBaseUrl(config) + "/" + record.name + "/-/pipelines/" + ((record.pipelineId) ? record.pipelineId : "")
            } else {
                pipelineUrl=getGitlabBaseUrl(config) + "/" + record.name + "/-/pipelines"
            }

            let gitRepoUrl=getGitlabBaseUrl(config) + "/" + record.name
            let appUrl="https://"+appName+"."+record.namespace+".nonprod.trustedfabric.org"

            let appLink=(
                <Tag color="blue" key="app">
                    <a className="action-tag" 
                        href={appUrl}
                        title="Open app URL"
                        target="_blank"
                        rel="noreferrer">
                        App
                    </a>
                </Tag>
            )

            return (
                <span className="links">
                    <Tag color="blue" key="log">
                        <a className="action-tag" 
                            href={logFullUrl}
                            title="Open app logs"
                            target="_blank"
                            rel="noreferrer">
                            Log
                        </a>
                    </Tag>
                    <Tag color="blue" key="pipeline">
                        <a className="action-tag" 
                            href={pipelineUrl}
                            title="Open app pipeline"
                            target="_blank"
                            rel="noreferrer">
                            Pipeline
                        </a>
                    </Tag>
                    <Tag color="blue" key="repo">
                        <a className="action-tag" 
                            href={gitRepoUrl}
                            title="Open app repository"
                            target="_blank"
                            rel="noreferrer">
                            Repo
                        </a>
                    </Tag>

                    {record.namespace!=='support'?appLink:''}

                </span>
            )
        },
      },




      // {
      //   key: 12,
      //   field: "pipelineId",
      //   dataIndex: "pipelineId",
      //   title: "pipelineId",
      //   //width: "1fr",
      //   //render: descriptionRenderer,
      // },

    ];
  };


  /********************************************/
  const expandedPodRowRender = (pod) => {
    const columns = [
      { title: 'Container Name', dataIndex: 'name', key: 'name' },
      { title: 'Started', dataIndex: 'started', key: 'started', render: (value) => (<span>{value ? "true" : "false"}</span>) },
      { title: 'Ready', dataIndex: 'ready', key: 'ready', render: (value) => (<span>{value ? "true" : "false"}</span>) },
      { title: 'Restarts', dataIndex: 'restartCount', key: 'restartCount' },
      { title: 'runningStartedAt', dataIndex: 'runningStartedAt', key: 'runningStartedAt', render: (text, record) => (
        <Tooltip title={moment(text).format("YYYY-MM-DD hh:mm:ss a")}>
          <span>{text?moment(text).fromNow():''}</span>
        </Tooltip>
      ), },
      { title: 'lastTerminatedStartedAt', dataIndex: 'lastTerminatedStartedAt', key: 'lastTerminatedStartedAt', render: (text, record) => (
        <Tooltip title={moment(text).format("YYYY-MM-DD hh:mm:ss a")}>
          <span>{text?moment(text).fromNow():''}</span>
        </Tooltip>
      ), },
      { title: 'lastTerminatedFinishedAt', dataIndex: 'lastTerminatedFinishedAt', key: 'lastTerminatedFinishedAt', render: (text, record) => (
        <Tooltip title={moment(text).format("YYYY-MM-DD hh:mm:ss a")}>
          <span>{text?moment(text).fromNow():''}</span>
        </Tooltip>
      ), },
      { title: 'lastTerminatedReason', dataIndex: 'lastTerminatedReason', key: 'lastTerminatedStartedAt' },
      { title: 'lastTerminatedExitCode', dataIndex: 'lastTerminatedExitCode', key: 'lastTerminatedExitCode' },
    ];
    return (
      <Table
        columns={columns}
        dataSource={pod.containers}
        rowKey="uniqueKey"
        pagination={false}
      />
    );
  };

  const expandedDeploymentRowRender = (deployment) => {
    const columns = [
      { 
        title: 'Pod Name', dataIndex: 'name', key: 'name',
        render: (text, record) => {
            const healthlyPods = record.containers?record.containers.every(function(container) {
                return container.started===true && container.ready===true;
            }):false;
            if (healthlyPods===true) {
                return (
                    <span>{text}</span>
                )
            } else {
                return (
                    <span>
                        <Tooltip title="At least one container in this pod is unhealthy">
                        {text}
                        &nbsp;&nbsp;
                        {healthlyPods?null:<Badge status="red" />}
                        </Tooltip>
                    </span>
                )
            }
        },
      },
      { title: 'Pod Version', dataIndex: 'version', key: 'version' },
      {
        key: "containerTotalRestartCount",
        field: "containerTotalRestartCount",
        dataIndex: "containerTotalRestartCount",
        title: "Restarts",
        width: "100px",
        ellipsis: false,
        sorter: {
          compare: (a, b) => a.containerTotalRestartCount > b.containerTotalRestartCount,
        },
      },
      {
        title: 'Restarted',
        key: 'restartedAt',
        dataIndex: "restartedAt",
        // render: (text, pod) => (
        //     <Tooltip title={moment(text).format("YYYY-MM-DD hh:mm:ss a")}>
        //     <Badge status="success" />
        //     {pod.restartedAt} x {text}
        //     </Tooltip>
        // ),

        render: (text, record) => (
            <Tooltip title={moment(text).format("YYYY-MM-DD hh:mm:ss a")}>
              <span>{text?moment(text).fromNow():''}</span>
            </Tooltip>
          ),

      },
      {
        key: "links",
        field: "links",
        dataIndex: "links",
        title: "Links",
        width: "100px",
        ellipsis: false,
        render: (text, record) => {
            let appName=record.name
            let indexGuid=kibanaIndexes[record.namespace]
            let logParam="?_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-24h,to:now))&_a=(columns:!('@timestamp',kubernetes.pod_name,message,java.level,java.logger,java.thread,java.stack),filters:!(('$state':(store:appState),meta:(alias:!n,disabled:!f,index:'"+indexGuid+"',key:kubernetes.pod_name,negate:!f,params:(query:"+appName+"),type:phrase),query:(match_phrase:(kubernetes.pod_name:"+appName+")))),index:'"+indexGuid+"',interval:auto,query:(language:kuery,query:''),sort:!(!('@timestamp',desc)))"
            let logFullUrl=logUrl+logParam
            return (
                <span className="links">
                    <Tag color="blue" key="log">
                        <a className="action-tag" 
                            href={logFullUrl}
                            title="Open app logs"
                            target="_blank"
                            rel="noreferrer">
                            Log
                        </a>
                    </Tag>
                </span>
            )
        },
      },


    ];
    return (
      <Table
        columns={columns}
        dataSource={deployment.pods}
        rowKey="uniqueKey"
        pagination={false}
        expandable={{ expandedRowRender: pod => (expandedPodRowRender(pod)) }}
      />
    );
  };


  // if (deploymentsData.state === "hasError") {
  //   return <div>There is some problem!</div>;
  // }

  // if (deploymentsData.state === "loading") {
  //   return <div>Loading...</div>;
  // }

  // if (true || deploymentsData.state === "hasValue") {

  // useEffect(() => {
  //   console.log("state", deploymentsData.state);
  // });

  return (
    <div>
      <Button type="primary"
        onClick={() => { resetDeploymentsData() }}
        // loading={deploymentsData.state === "loading" ? { delay: 1 } : false}
        style={{ marginBottom: 16 }}>
        Refresh
      </Button>

      <Table style={{ border: '0px solid red', display: deploymentsData.state === "hasValue" ? 'inline' : 'none' }}
        //tableLayout='fixed'
        //sticky={true}
        //loading={this.state.clearFinishedJobsRunning}
        pagination={false}
        columns={getColumns()}
        dataSource={deploymentsData.state === "hasValue" ? deploymentsData.contents : []}
        rowKey="uniqueKey"
        //dataSource={deploymentList}
        //rowClassName={rowClass}
        //rowSelection={rowSelection}
        scroll={{ y: 'calc(100vh - 200px)', x: true }}
        showSorterTooltip={true}
        size="small"
        // expandable={ (record) => expandedRowRender (record) }
        expandable=

        {{
          expandedRowRender: record => (expandedDeploymentRowRender(record)),
          // rowExpandable: record => record.name !== "Not Expandable"
        }}


        onRow={(record) => ({
          onClick: () => {
            selectRow(record);
          },
        })}
      />
    </div>
  );

}

export default DeploymentsTable
