import { atom } from "recoil";

export const animeTitles = atom({
  key: "animeTitleList",
  default: [],
});


export const animeListPageNum = atom({
    key: "animeListPageNum",
    default: 0,
  });

// export const slicedAnimeTitles = selector({
//     key: "slicedAnimeTitles",
//     get: ({ get }) => {
//       const animes = get(animeTitles);
//       const pageNum = get(animeListPageNum);
//       const newAnimeList = [...animes];
//       const arrIndex = pageNum === 0 ? 0 : pageNum * 50 + 1;
//       return newAnimeList.splice(arrIndex, 50);
//     },
//   });

export const configStore = atom({
    key: 'config', // unique ID (with respect to other atoms/selectors)
    default: "", // default value (aka initial value)
  });

export const configReadyStore = atom({
    key: 'configReady',
    default: false,
  });


export const connectState = atom({
    key: 'connectState', // unique ID (with respect to other atoms/selectors)
    default: false, // default value (aka initial value)
  });

export const listeningState = atom({
    key: 'listeningState', // unique ID (with respect to other atoms/selectors)
    default: "Unknown", // default value (aka initial value)
  });


export const encryptorPasswordStore = atom({
    key: 'encryptorPassword', // unique ID (with respect to other atoms/selectors)
    default: "", // default value (aka initial value)
  });