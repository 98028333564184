import axios from "axios";
//import Config from './Config';


export async function getEvents(configData) {

    const baseOperationsApiBaseUrl = configData.UI_CONFIG_OPERATIONS_API_BASE_URI;
    const eventApiUrl = baseOperationsApiBaseUrl + "/v1/events";
    //const eventApiUrl = "https://tf-operations.nonprod.trustedfabric.org/v1/events";
    console.log("eventApiUrl", eventApiUrl);
    try {
      const response = await axios.get(eventApiUrl);
      console.log("Got data");
      return response.data || [];
    } catch (error) {
      throw new Error(
        `Error in 'axiosGetJsonData(${eventApiUrl})': ${error.message}`
      );
    }

}

