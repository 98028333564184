
import { useRecoilStateLoadable, useResetRecoilState } from "recoil";
import { Badge, Button, Tooltip, Table } from 'antd';

import { getGitlabBaseUrl, configState } from "../store/config-store";
import { deploymentListState, allDeploymentsState } from "../store/deployment-store";


const VersionsTable = () => {

   const [config] = useRecoilStateLoadable(configState);
   const [deploymentsData] = useRecoilStateLoadable(deploymentListState);

   const resetDeploymentsData = useResetRecoilState(allDeploymentsState);
   //const refreshDeployments = useRecoilRefresher_UNSTABLE(allDeploymentsState());

   const getVersionData = () => {

      const standardEnvNames = ["development", "qa", "qa2", "uat", "uat2"];

      let deploymentVersions = new Map();
      let deployments = deploymentsData.contents;
      deployments.forEach((deployment, i) => {
         if (standardEnvNames.includes(deployment.namespace)) {
            var deploymentVersionInfo = deploymentVersions.get(deployment.name)
            if (deploymentVersionInfo == null) {
               deploymentVersionInfo = {
                  uniqueKey: deployment.name,
                  name: deployment.name,
               }
               deploymentVersions.set(deployment.name, deploymentVersionInfo);
            }
            // Check if all pod version match the deplyment version.
            // If not, there is a rolling upgrade in progress (or failed)
            const versionsMatch = deployment.pods.every(function(pod) {
                return pod.version==null || pod.version === deployment.version;
             });
            const version = versionsMatch?deployment.version:"Multiple";
            const pipelineId = versionsMatch?deployment.pipelineId:"";
            deploymentVersionInfo['version-' + deployment.namespace] = version;
            deploymentVersionInfo['pipelineId-' + deployment.namespace] = pipelineId;

            const healthlyPods = deployment.pods.every(function(pod) {
                if (pod.containers) {
                    return pod.containers.every(function(container) {
                        return container.started===true && container.ready===true;
                    })
                } else {
                    return false;
                }
            });

            deploymentVersionInfo['healthlyPods-' + deployment.namespace] = healthlyPods;
    
         }
      });
      const values = Array.from(deploymentVersions.values());
      console.log(values);
      return values;
   }

   const versionDevelopmentRender = (text, record) => {
      return versionFlagRender(text, record, 'development', null)
   }

   const versionQaRender = (text, record) => {
      return versionFlagRender(text, record, 'qa', 'development')
   }


   const versionQa2Render = (text, record) => {
    return versionFlagRender(text, record, 'qa2', 'qa')
   }

   const versionUatRender = (text, record) => {
      return versionFlagRender(text, record, 'uat', 'qa2')
   }

   const versionUat2Render = (text, record) => {
    return versionFlagRender(text, record, 'uat2', 'uat')
 }

   const versionFlagRender = (text, record, envName, priorEnvName) => {
      if (text) {
         const pipelineId = record['pipelineId-'+envName];
         return ( 
            <span>
               <a className="newtab" href={getGitlabBaseUrl(config) + "/" + record.name + "/-/pipelines/" + ((pipelineId) ? pipelineId : "")}
                  title="Open app pipeline in seperate tab"
                  target="_blank"
                  rel="noreferrer"
               >
                  {text}
               </a>
               &nbsp;
               {text&&priorEnvName&&record['version-'+priorEnvName]!==text?<Badge status="gold" />:null}
               <Tooltip title="At least one pod is unhealthy">
                &nbsp;
                {record['healthlyPods-'+envName]?null:<Badge status="red" />}
               </Tooltip>


            </span>
         )
      }
   }

   const getColumns = () => {
      return [

         {
            key: "name",
            field: "name",
            dataIndex: "name",
            title: "Deployment Name",
            width: "100px",
            fixed: 'left',
            ellipsis: true,
            sorter: {
               compare: (a, b) => a.name.localeCompare(b.name),
               multiple: 1,
            },
            defaultSortOrder: "ascend",
            render: (text, record) => (
               <a className="newtab" href={getGitlabBaseUrl(config) + "/" + record.name}
                  title="Open app repo in seperate tab"
                  target="_blank"
                  rel="noreferrer"
               >
                  {text}
               </a>)
         },
         {
            key: "version-development",
            field: "version-development",
            dataIndex: "version-development",
            title: "Development Version",
            width: "60px",
            sorter: {
               compare: (a, b) => a.version.localeCompare(b.version),
            },
            render: versionDevelopmentRender,
         },

         {
            key: "version=qa",
            field: "version-qa",
            dataIndex: "version-qa",
            title: "QA Version",
            width: "60px",
            sorter: {
               compare: (a, b) => a.version.localeCompare(b.version),
            },
            render: versionQaRender,
         },

         {
            key: "version-qa2",
            field: "version-qa2",
            dataIndex: "version-qa2",
            title: "QA2 Version",
            width: "60px",
            sorter: {
               compare: (a, b) => a.version.localeCompare(b.version),
            },
            render: versionQa2Render,
         },

         {
            key: "version-uat",
            field: "version-uat",
            dataIndex: "version-uat",
            title: "UAT Version",
            width: "60px",
            sorter: {
               compare: (a, b) => a.version.localeCompare(b.version),
            },
            render: versionUatRender,
         },

         {
            key: "version-uat2",
            field: "version-uat2",
            dataIndex: "version-uat2",
            title: "UAT2 Version",
            width: "60px",
            sorter: {
               compare: (a, b) => a.version.localeCompare(b.version),
            },
            render: versionUat2Render,
         },

      ];
   };


   return (
      <div>
         <Button type="primary"
            onClick={() => { resetDeploymentsData() }}
            style={{ marginBottom: 16 }}>
            Refresh
         </Button>
         <span style={{float: 'right', marginLeft:'10px'}}>
             <Badge status="gold" />= Out of date with prior environment<br/>
             <Badge status="red" />= At least one pod is unhealthy
         </span>
         <Table style={{ border: '0px solid yellow', display: deploymentsData.state === "hasValue" ? 'inline' : 'none' }}
            pagination={false}
            columns={getColumns()}
            dataSource={deploymentsData.state === "hasValue" ? getVersionData() : []}
            rowKey="uniqueKey"
            scroll={{ y: 'calc(100vh - 200px)', x: true }}
            showSorterTooltip={true}
            size="small"
            
         />
         {/* <span style={{ border: '2px solid red'}}>Key: <Badge status="gold" />=Out of date</span> */}
      </div>
   );

}

export default VersionsTable