import React from 'react';
//import ReactDOM from 'react-dom/client'; // v18
import ReactDOM from 'react-dom';
import { RecoilRoot } from "recoil";
import { QueryParamProvider } from 'use-query-params';

import './theme/index.less';

//import './index.css';
import App from './App';

import reportWebVitals from './reportWebVitals';

// const root = ReactDOM.createRoot(document.getElementById('root'))
// root.render(<RecoilRoot><App /></RecoilRoot>)

ReactDOM.render(
    <React.StrictMode>
      <RecoilRoot>
      <QueryParamProvider >
        <App />
        </QueryParamProvider>
      </RecoilRoot>
    </React.StrictMode>,
    document.getElementById('root')
  );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
