import axios from "axios";

const CONFIG_FILE = "/config.json"

// class Config {

//     constructor() {
//         this.data = null;
//     }


//     loadX() {
//         axios.get(CONFIG_FILE).then(res => {
//             const persons = res.data;
//             this.setState({ persons });
//         })
//     }

//     load(setFunctionRef) {
//         return fetch(CONFIG_FILE)
//             .then(response => response.json())
//             .then(data => { console.log(data); this.data = data; setFunctionRef(data)} )
//     }

//     isReady() {
//         return this.data != null;
//     }

//     getData() {
//         return this.data;
//     }

//     baseOperationsApiBaseUri() {
//         return this.data.UI_CONFIG_OPERATIONS_API_BASE_URI;
//     }

//     baseOperationsGitlabBaseUrl() {
//         return this.data.UI_CONFIG_OPERATIONS_GITLAB_BASE_URL;
//     }

// }

// export default new Config();



export async function getConfig() {

    console.log("CONFIG_FILE:", CONFIG_FILE);
    try {
        const response = await axios.get(CONFIG_FILE);
        console.log("Got data");
        return response.data || [];
    } catch (error) {
        throw new Error(
            `Error in 'axiosGetJsonData(${CONFIG_FILE})': ${error.message}`
        );
    }

}
