
import { useRecoilStateLoadable, useResetRecoilState } from "recoil";
import { Button, Tooltip, Table } from 'antd';
import moment from 'moment';

// import { getGitlabBaseUrl, configState } from "../store/config-store";
import { eventListState, allEventsState } from "../store/event-store";


const EventsTable = () => {

//   const [config] = useRecoilStateLoadable(configState);
  const [eventsData] = useRecoilStateLoadable(eventListState);
  const resetEventsData = useResetRecoilState(allEventsState);
 
  const getColumns = () => {
    return [
      {
        key: "eventTimestamp",
        field: "eventTimestamp",
        dataIndex: "eventTimestamp",
        title: "Event Timestamp",
        width: "80px",
        fixed: 'left',
        ellipsis: true,
        sorter: {
            compare: (a, b) => a.eventTimestamp.localeCompare(b.eventTimestamp),
        },
        render: (text, record) => (
            <Tooltip title={moment(text).format("YYYY-MM-DD hh:mm:ss a")}>
            <span>{text?moment(text).fromNow():''}</span>
            </Tooltip>
        ),
        defaultSortOrder: "descend",
      },
      {
        key: 2,
        field: "namespace",
        dataIndex: "namespace",
        title: "Namespace",
        width: "100px",
        fixed: 'left',
        ellipsis: true,
        sorter: {
          compare: (a, b) => a.namespace.localeCompare(b.namespace),
          multiple: 1,
        },
        //defaultSortOrder: "ascend",
      },
      {
        key: 3,
        field: "name",
        dataIndex: "name",
        title: "Name",
        width: "100px",
        fixed: 'left',
        ellipsis: true,
        sorter: {
          compare: (a, b) => a.name.localeCompare(b.name),
          multiple: 1,
        },
        //defaultSortOrder: "ascend",
      },
      {
        key: 4,
        field: "kind",
        dataIndex: "kind",
        title: "Kind",
        width: "60px",
        sorter: {
          compare: (a, b) => a.kind.localeCompare(b.kind),
        },
      },
      {
        key: 6,
        field: "type",
        dataIndex: "type",
        title: "Type",
        width: "60px",
        ellipsis: true,
        sorter: {
          compare: (a, b) => a.type.localeCompare(b.type),
          multiple: 1,
        },
        render: (text, record) => {
          switch(text) {
              case 'Warning': return(<span style={{color:'yellow'}} >{text}</span>)
              default: return(text) 
          }
        },
      },
      {
        key: 9,
        field: "reason",
        dataIndex: "reason",
        title: "Reason",
        width: "65px",
        ellipsis: true,
        sorter: {
          compare: (a, b) => a.reason.localeCompare(b.reason),
        },
      },
      {
        key: "duration",
        field: "duration",
        dataIndex: "duration",
        title: "Duration",
        width: "100px",
        ellipsis: true,
        // sorter: {
        //   compare: (a, b) => a.count > b.count,
        // },
        render: (text, record) => (
            <Tooltip title={"Duration: "+text}>
              <span>{moment.duration(text).humanize()}</span>
            </Tooltip>
          ),
      },
      {
        key: "count",
        field: "count",
        dataIndex: "count",
        title: "Count",
        width: "100px",
        ellipsis: false,
        sorter: {
          compare: (a, b) => a.count > b.count,
        },
      },
      {
        key: "note",
        field: "note",
        dataIndex: "note",
        title: "Note",
        width: "200px",
        //width: "1fr",
        ellipsis: true,
        sorter: {
          compare: (a, b) => a.note.localeCompare(b.note),
        },
      },

    ];
  };

  return (
    <div>
      <Button type="primary"
        onClick={() => { resetEventsData() }}
        style={{ marginBottom: 16 }}>
        Refresh
      </Button>

      <Table style={{ border: '0px solid red', display: eventsData.state === "hasValue" ? 'inline' : 'none' }}
        pagination={false}
        columns={getColumns()}
        dataSource={eventsData.state === "hasValue" ? eventsData.contents : []}
        rowKey="uniqueKey"
        scroll={{ y: 'calc(100vh - 200px)', x: true }}
        showSorterTooltip={true}
        size="small"
      />
    </div>
  );

}

export default EventsTable