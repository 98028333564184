
import { atom, selector, DefaultValue } from "recoil";
import { configState } from "./config-store";
import { getDeployments } from "../services/DeploymentService";

// https://javascript.plainenglish.io/getting-to-know-recoil-initializing-and-maintaining-react-application-state-with-asynchronous-c5c3eb114c39


const deploymentListReloadTrigger = atom({
    key: "deploymentListReloadTrigger",
    default: 0
  });


/**
 * Populate the default selector return value with a service call.
 */
export const allDeploymentsState = selector({
  key: "allDeploymentsState",
  get: async ({ get }) => {

    get(deploymentListReloadTrigger);
    
    const configData = get(configState);
    console.log("new config:", configData);

    try {
      const response = await getDeployments(configData);
      console.log("getDeployments called...");
      return response.data.deployments || [];
    } catch (error) {
      console.error(`allDeploymentsState -> getDeployments() ERROR: \n${error}`);
      return [];
    }
  },
  
  set: ({ set }, value) => {
    if (value instanceof DefaultValue) {
      set(deploymentListReloadTrigger, v => v + 1);
    }
  }

});

/**
 * This is the atom the UI components will use to display state.
 */
export const deploymentListState = atom({
  key: "deploymentListState",
  default: allDeploymentsState
});